.layout {
  height: 100%;
}

.layout :global .ant-layout-header {
  height: var(--wrw-applayout-header-height);
  line-height: var(--wrw-applayout-header-height);
}

.layout :global .ant-layout-trigger {
  top: var(--wrw-applayout-header-height);
}

.mainContent {
  height: calc(100vh - var(--wrw-applayout-header-height));
}

.sider {
  background-color: var(--wrw-secondary-background-color);
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0;
  background: var(--wrw-primary-background-color);
}

.layout :global(.ant-menu:not(.ant-menu-horizontal)) {
  background: unset;
}
.layout :global(.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected) {
  background: #fff;
}

.header:global.ant-layout-header {
  display: flex;
  position: relative;
  border-bottom: var(--wrw-header-lightborder-height) solid var(--wrw-header-lightborder-color);
  box-sizing: border-box;
  justify-content: center;
  height: var(--wrw-applayout-header-height);
}

.header::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: var(--wrw-header-darkborder-height);
  background-color: var(--wrw-header-darkborder-color);
}

.logo {
  position: absolute;
  left: var(--wrw-margin-lg);
  top: calc(
    (
        var(--wrw-applayout-header-height) - var(--wrw-logo-height) - var(--wrw-header-darkborder-height) -
          var(--wrw-header-lightborder-height)
      ) / 2
  );
}

.actions {
  position: absolute;
  top: calc(
    (
        var(--wrw-applayout-header-height) - 32px - var(--wrw-header-darkborder-height) -
          var(--wrw-header-lightborder-height)
      ) / 2
  );
  right: var(--wrw-margin-lg);
  height: 32px;
}

@media only screen and (max-width: 730px) {
  .header:global.ant-layout-header {
    justify-content: flex-start;
  }
}

.headerActions {
  margin-right: var(--wrw-margin-sm);
  display: flex;
}

.application {
  color: var(--wrw-text-color);
  font-weight: bold;
  font-size: 20px;
}

.long {
  display: flex;
  flex-direction: column;
  line-height: normal;
}

.title {
  text-align: center;
  margin-top: 18px;
}

.sub {
  font-weight: normal;
  color: var(--wrw-color-gray-8);
  font-size: 16px;
}

.short {
  display: none;
}

@media only screen and (max-width: 730px) {
  .long {
    display: none;
  }

  .short {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .sub {
    display: none;
  }

  .title {
    height: auto;
    vertical-align: middle;
    margin: 0;
    line-height: calc(
      var(--wrw-applayout-header-height) - var(--wrw-header-darkborder-height) - var(--wrw-header-lightborder-height)
    );
  }
}
