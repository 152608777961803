.divider:global.ant-divider {
  margin: 0;
}

.iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.button:global.ant-btn {
  width: 100%;
  text-align: left;
  padding: 0;
  height: auto;
}
