.logo {
  display: block;
}

.background {
  position: relative;
  display: flex;
  flex-direction: column;
  background: var(--wrw-primary-background-color);
  width: 100%;
  height: 100%;
  overflow: auto;
}

@media print {
  .background {
    overflow: visible;
  }
}
